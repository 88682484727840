import { useUserStore } from './UserStore';
import { defineStore } from 'pinia';
import type { VehicleResp } from '../repository/types/response';
import type { ApplyVoucherReq, CheckoutReq } from '../repository/types/request';
import type { IApiInstance } from '../types/plugins';
import type { Country, State, Ticket, WemotooEvent } from '../types';

export type TicketQuantity = Ticket & { quantity: number };

export type PurchaseTicket = {
	event: WemotooEvent | null;
	tickets: TicketQuantity[];
	promocode: string;
	participant_details?: ParticipantDetails | null;
	subtotal: string;
	discount: string;
	total: string;
	origTotal: string;
	shipping: string;
};

export type ParticipantDetails = {
	name: string | null;
	nickname: string | null;
	ic_no: string | null;
	country_code: string | null;
	contact: string | null;
	blood_type: string | null;
	team_name: string | null;
	state_id: string | null;
	district: string | null;
	country_id: string | null;
	vehicle_id: string | null;
	vehicle_list: VehicleResp[];
	default_vehicle?: VehicleResp | null;
};
const initialState: PurchaseTicket = {
	event: null,
	tickets: [],
	promocode: '',
	// participant_details: {
	// 	name: "",
	// 	nickname: "",
	// 	ic_no: "",
	// 	contact: "",
	// 	country_code: "+60" as string,
	// 	blood_type: "",
	// 	team_name: "",
	// 	state_id: null,
	// 	district: "",
	// 	vehicle_id: null,
	// 	country_id: null,
	// },
	participant_details: null,
	total: '',
	origTotal: '',
	subtotal: '',
	discount: '0',
	shipping: '0',
};

export const usePurchaseTicketStore = defineStore({
	id: 'purchaseTicketStore',
	state: () => ({
		loading: false as boolean,
		purchaseTicket: structuredClone(initialState),
		errors: [] as string[],
	}),
	getters: {
		vehicleIdGetter(): string {
			let defaultVehicleId: number | undefined | null = 0;

			defaultVehicleId = this.participantDetails?.default_vehicle?.id;
			if (defaultVehicleId) {
				// Ensure participantDetails is not null before accessing vehicle_id
				if (this.participantDetails) {
					this.participantDetails.vehicle_id = `${defaultVehicleId}`;
				}
			}
			return `${defaultVehicleId}`;
		},
		totalQuantity(): number {
			let quantity: number = 0;
			this.purchaseTicket.tickets.forEach((ticket) => {
				quantity += ticket.quantity;
			});

			return quantity;
		},
		quantityByTicket: (state) => {
			return (ticketId: number) => {
				const quantity: number = 0;

				const index: number = state.purchaseTicket.tickets.findIndex((t) => t.id === ticketId);
				if (index !== -1) {
					return state.purchaseTicket.tickets[index].quantity;
				}

				return quantity;
			};
		},
		participantDetails: (state) => {
			return state.purchaseTicket.participant_details;
		},

		getStateName(): string {
			let stateName = '';
			const utilsState = useUtilsDataStore();
			const { states } = storeToRefs(utilsState);

			if (this.purchaseTicket.participant_details?.state_id == null) return '';

			const state = states.value.find((state: State) => state.id === Number(this.purchaseTicket.participant_details?.state_id));
			if (state != null) {
				stateName = state.name;
			}
			return stateName;
		},
	},
	actions: {
		addTicket(ticket: Ticket) {
			if (this.purchaseTicket.tickets.length > 0) {
				const index: number = this.purchaseTicket.tickets.findIndex((t) => t.id === ticket.id);
				if (index !== -1) {
					this.purchaseTicket.tickets[index].quantity += 1;
				} else {
					this.purchaseTicket.tickets.push({ ...ticket, quantity: 1 });
				}
			} else {
				this.purchaseTicket.tickets.push({ ...ticket, quantity: 1 });
			}
			this.recalculate();
		},
		removeTicket(ticket: Ticket) {
			const index: number = this.purchaseTicket.tickets.findIndex((t) => t.id === ticket.id);
			if (index !== -1) {
				this.purchaseTicket.tickets[index].quantity -= 1;

				if (this.purchaseTicket.tickets[index].quantity === 0) {
					this.purchaseTicket.tickets.splice(index, 1);
				}
			}
			this.recalculate();
		},
		recalculate() {
			let totalAmount: number = 0;
			this.purchaseTicket.tickets.forEach((ticket) => {
				totalAmount += ticket.quantity * Number(ticket.price);
			});

			this.purchaseTicket.subtotal = totalAmount.toFixed(2);
			this.purchaseTicket.total = totalAmount.toFixed(2);
			this.purchaseTicket.origTotal = totalAmount.toFixed(2);
			this.purchaseTicket.discount = '0';
			this.purchaseTicket.shipping = '0';
		},
		updateParticipantDetails(details: ParticipantDetails) {
			this.purchaseTicket.participant_details = {
				...this.purchaseTicket.participant_details,
				...details,
			};
		},
		updateStateId(id: number | null) {
			if (id == null) return;

			if (this.purchaseTicket.participant_details) {
				this.purchaseTicket.participant_details.state_id = id!.toString();
			}
		},
		updateEvent(event: WemotooEvent) {
			this.purchaseTicket.event = event;
		},
		updateVehicleList(vehicle: VehicleResp) {
			this.purchaseTicket.participant_details?.vehicle_list.push(vehicle);
		},
		removePromocode() {
			this.purchaseTicket.promocode = '';
			this.recalculate();

			return true;
		},

		async applyPromoCode(promocode: string) {
			this.loading = true;
			let result = false;

			try {
				const userStore = useUserStore();

				const voucherReq: ApplyVoucherReq & any = {
					tickets: this.purchaseTicket.tickets,
					promocode: promocode,
				};

				const $api = useNuxtApp().$api as IApiInstance;
				const response = await $api.voucher.applyVoucher(voucherReq);

				if (response) {
					const firebase = useFirebaseStore();
					firebase.logCustomEvent(APPLY_VOUCHER, {
						transaction_id: `APPLY VOUCHER/${userStore.user?.id}/${this.purchaseTicket.event?.id}`,
						event_id: this.purchaseTicket.event?.id,
						event_name: this.purchaseTicket.event?.name,
						value: Number(this.purchaseTicket.total ?? '0'),
						coupon: this.purchaseTicket.promocode,
						discount: this.purchaseTicket.discount,
						shipping: this.purchaseTicket.shipping,
						currency: 'MYR',
						items: [
							...this.purchaseTicket.tickets.map((ticket) => {
								return {
									item_id: ticket.id,
									item_name: ticket.name,
									quantity: Number(ticket.quantity),
									price: Number(ticket.price ?? '0'),
								};
							}),
						],
					});

					this.purchaseTicket.promocode = promocode;
					this.purchaseTicket.subtotal = response.subtotal;
					this.purchaseTicket.discount = response.discount;
					this.purchaseTicket.total = response.total;
					this.purchaseTicket.shipping = response.shipping;
					result = true;
					return result;
				}
			} catch (error: any) {
				console.error(error);
				this.removePromocode();
				this.errors = [];
				this.errors.push(error);
				result = false;
				return result;
			} finally {
				this.loading = false;
			}
		},
		async getEventPrefillData() {
			this.loading = true;
			const userStore = useUserStore();
			const utilsState = useUtilsDataStore();
			const { countries } = storeToRefs(utilsState);
			const defaultMalaysia = countries.value.find((country: Country) => country.id === 129);

			try {
				if (
					!this.purchaseTicket.participant_details ||
					(this.purchaseTicket.participant_details && (!this.purchaseTicket.participant_details.name || this.purchaseTicket.participant_details.name == ''))
				) {
					const $api = useNuxtApp().$api as IApiInstance;
					const response = await $api.event.getEventPrefill();

					if (response) {
						this.updateParticipantDetails({
							name: response.name ?? userStore.user?.name ?? null,
							nickname: response.nickname ?? userStore.user?.name ?? null,
							ic_no: response.ic_no ?? userStore.user?.ic_no ?? null,
							contact: userStore.user?.contact ?? null,
							country_id: response.country_id ? `${response.country_id}` : defaultMalaysia?.id.toString() ?? null,
							blood_type: response.blood_type,
							team_name: response.team_name,
							state_id: `${response.state_id}`,
							district: response.district,
							country_code: userStore.user?.country_code ?? '+60',
							vehicle_id: '',
							vehicle_list: [],
						});
					} else {
						this.updateParticipantDetails({
							name: userStore.user?.name ?? null,
							nickname: userStore.user?.name ?? null,
							ic_no: userStore.user?.ic_no ?? null,
							contact: userStore.user?.contact ?? null,
							country_id: defaultMalaysia?.id.toString() ?? null,
							blood_type: null,
							team_name: null,
							state_id: null,
							district: null,
							country_code: userStore.user?.country_code ?? '+60',
							vehicle_id: '',
							vehicle_list: [],
						});
					}
				}
			} catch (error) {
				this.updateParticipantDetails({
					name: userStore.user?.name ?? null,
					nickname: userStore.user?.name ?? null,
					ic_no: userStore.user?.ic_no ?? null,
					contact: userStore.user?.contact ?? null,
					country_id: defaultMalaysia?.id.toString() ?? null,
					blood_type: null,
					team_name: null,
					state_id: null,
					district: null,
					country_code: userStore.user?.country_code ?? '+60',
					vehicle_id: '',
					vehicle_list: [],
				});
				console.error(error);
			} finally {
				this.loading = false;
			}
		},
		async checkout() {
			this.loading = true;

			const firebase = useFirebaseStore();

			const userStore = useUserStore();

			try {
				const checkoutReq: CheckoutReq & any = {
					// blood_type: this.purchaseTicket.participant_details.blood_type,
					contact: this.purchaseTicket.participant_details?.contact,
					country_id: this.purchaseTicket.participant_details?.country_id,
					district: this.purchaseTicket.participant_details?.district,
					ic_no: this.purchaseTicket.participant_details?.ic_no,
					name: this.purchaseTicket.participant_details?.name,
					nickname: this.purchaseTicket.participant_details?.nickname,
					payment_method: 'ipay',
					promocode: this.purchaseTicket.promocode || '',
					state_id: this.purchaseTicket.participant_details?.state_id,
					team_name: this.purchaseTicket.participant_details?.team_name,
					// vehicle_id: this.purchaseTicket.participant_details?.vehicle_id,
					tickets: this.purchaseTicket.tickets,
					source: 'web',
				};

				const $api = useNuxtApp().$api as IApiInstance;
				const response = await $api.order.checkout(checkoutReq);

				if (response != null) {
					firebase.logCustomEvent(PURCHASE, {
						transaction_id: response?.order_id,
						event_id: this.purchaseTicket.event?.id,
						event_name: this.purchaseTicket.event?.name,
						value: Number(this.purchaseTicket.total ?? '0'),
						coupon: this.purchaseTicket.promocode,
						discount: this.purchaseTicket.discount,
						shipping: this.purchaseTicket.shipping,
						currency: 'MYR',
						items: [
							...this.purchaseTicket.tickets.map((ticket) => {
								return {
									item_id: ticket.id,
									item_name: ticket.name,
									quantity: Number(ticket.quantity),
									price: Number(ticket.price ?? '0'),
								};
							}),
						],
						status: response?.completed,
					});

					this.purchaseTicket = structuredClone(initialState);

					return response;
				} else {
					return undefined;
				}
			} catch (error: any) {
				firebase.logCustomEvent(PURCHASE_ERROR, {
					transaction_id: `PURCHASE ERROR/${userStore.user?.id}/${this.purchaseTicket.event?.id}`,
					log_date: new Date().toISOString(),
					contact: this.purchaseTicket.participant_details?.contact,
					country_id: this.purchaseTicket.participant_details?.country_id,
					district: this.purchaseTicket.participant_details?.district,
					ic_no: this.purchaseTicket.participant_details?.ic_no,
					name: this.purchaseTicket.participant_details?.name,
					nickname: this.purchaseTicket.participant_details?.nickname,
					payment_method: 'ipay',
					promocode: this.purchaseTicket.promocode || '',
					state_id: this.purchaseTicket.participant_details?.state_id,
					team_name: this.purchaseTicket.participant_details?.team_name,
					tickets: this.purchaseTicket.tickets,
					errors: error?.data?.message,
				});

				console.error(error);

				this.errors = [];
				this.errors.push(error);
				return undefined;
			} finally {
				this.loading = false;
			}
		},
		async getDefaultVehicle() {
			const vehicleStore = useVehicleStore();
			await vehicleStore.fetchDefaultVehicle();

			if (this.participantDetails) {
				this.participantDetails.default_vehicle = vehicleStore.myDefaultVehicle;
			}
		},
	},
});
